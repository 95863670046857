/**
 * Styles FlexBox
 */

.dFlex {
    display: flex !important;
}

.dInlineFlex {
    display: inline-flex !important;
}

.flexColumn {
    flex-direction: column !important;
}

.flexColumnReverse {
    flex-direction: column-reverse !important;
}

.flexRowReverse {
    flex-direction: row-reverse !important;
}

.alignItemsCenter {
    align-items: center !important;
}

.alignItemsEnd {
    align-items: flex-end !important;
}

.alignItemsStart {
    align-items: flex-start !important;
}

.justifyContentCenter {
    justify-content: center !important;
}

.justifyContentBetween {
    justify-content: space-between !important;
}

.justifyContentEvenly {
    justify-content: space-evenly !important;
}

.justifyContentEnd {
    justify-content: flex-end !important;
}

.justifyContentStart {
    justify-content: flex-start !important;
}

.flexFullCenter {
    justify-content: center !important;
    align-items: center !important;
}

.alignContentStart {
    align-content: flex-start !important;
}

.flexShrink0 {
    flex-shrink: 0 !important;
}

.flexGrow1 {
    flex-grow: 1 !important;
}

.flexWrap {
    flex-wrap: wrap !important;
}

// margin
.ml40 {
    margin-left: rem(40);
}

.mr40 {
    margin-right: rem(40);
}
