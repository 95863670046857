/**
 Global Variables
 */

:root {
  --box-shadow: 0 4px 18px rgba(0, 0, 0, 0.08);
  --box-shadow-darker: 0 14px 24px rgba(0, 0, 0, 0.15);
  --box-shadow-card: 0px 4px 24px rgba(0, 0, 0, 0.15);;
  --box-shadow-search-dropdown: 0px 4px 24px rgba(0, 0, 0, 0.25);

  --border-radius: 6px;
  --border-radius-small: 5px;
  --border-radius-card: 10px;
  --border-radius-popup: 10px;
  --border-radius-large: 11px; // social icons
  --border-radius-circle: 50%;
  --border-radius-big: 100px;
}
