.client_card {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: var(--border-radius-card);
	box-shadow: inset 0 0 0 1px #eee;

	@include xs-block() {
		padding: rem(16) rem(13);
		height: rem(70);
	}

	@include md-block() {
		padding: rem(20) rem(40);
		height: rem(100);
	}

	img {
		max-height: 100%;
	}
}