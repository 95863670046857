/**
 Styles for Button Component


---iconButton + text-----

 button(type='button' class='btn danger iconButton')
  span.icon
    include ../../img/svg/header/basket-dark.svg
  span.text Купить
------
 */

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: rem(60);
  padding: 0 rem(20);
  font: rem(14)/rem(14) "Montserrat-600", sans-serif;
  text-align: center;
  vertical-align: bottom;
  border-radius: var(--button-border-radius);
  outline: none;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  transition:
    background .2s ease-in-out,
    border-color .2s ease-in-out,
    color .2s ease-in-out,
    box-shadow .2s ease-in-out;

  @include xs-block() {
    width: 100%;
    height: rem(40);
  }

  @include md-block() {
    width: auto;
    height: rem(60);
  }

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
    color: var(--button-disabled-color) !important;
    background: var(--button-disabled-bg-color) !important;
    border-color: var(--button-disabled-bg-color) !important;
    pointer-events: none;
  }

  &.big {
    height: rem(54);
    padding: 0 rem(30);
  }

  &.red {
    background: #BF0C0C;
  }

  &.success {
    color: var(--button-success-color);
    background-color: var(--button-success-bg-color);

    &:hover {
      color: var(--button-success-hover-color);
      box-shadow: inset 0 0 0 2px var(--button-success-hover-border-color);
      background-color: var(--button-success-hover-bg-color);
    }
  }

  &.secondary {
    color: var(--button-secondary-color);
    background-color: var(--button-secondary-bg-color);

    &:hover {
      @include md-block() {
        color: var(--button-secondary-hover-color);
        box-shadow: inset 0 0 0 2px var(--button-secondary-hover-border-color);
        background-color: var(--button-secondary-hover-bg-color);
      }
    }
  }

  &.secondary-transparent {
    color: var(--button-secondary-hover-color);
    box-shadow: inset 0 0 0 2px var(--button-secondary-hover-border-color);
    background-color: var(--button-secondary-hover-bg-color);

    &:hover {
      color: var(--button-secondary-color);
      background-color: var(--button-secondary-bg-color);
    }
  }

  &.secondary-transparent-white {
    color: var(--color-white);
    box-shadow: inset 0 0 0 2px var(--color-white);
    background-color: var(--button-secondary-hover-bg-color);

    &:hover {
      color: var(--color-base);
      background-color: var(--color-white);
    }
  }

  &.danger {
    color: var(--button-danger-color);
    background-color: var(--button-danger-bg-color);

    &.iconButton {
      .icon path {
        fill: var(--button-danger-color);
        stroke: var(--button-danger-color);
      }
    }

    &:hover {
      color: var(--button-danger-hover-color);
      background-color: var(--button-danger-hover-bg-color);
      box-shadow: inset 0 0 0 2px var(--button-danger-hover-border-color);

      &.iconButton {
        .icon path {
          fill: var(--button-danger-hover-color);
          stroke: var(--button-danger-hover-color);
        }
      }
    }
  }

  &.primary {
    color: var(--button-primary-color);
    background-color: var(--button-primary-bg-color);
    border: 2px solid var(--button-primary-border-color);

    &:hover {
      color: var(--button-primary-hover-color);
      background-color: var(--button-primary-hover-bg-color);

      &.iconButton {
        .icon path {
          fill: var(--button-primary-hover-color);
        }
      }
    }
  }

  &.transparent {
    font: rem(14)/rem(14) "Montserrat-500", sans-serif;
    height: rem(30);
    padding: rem(10) rem(15);
    font-size: rem(14);
    line-height: rem(14);
    color: var(--button-transparent-color);
    background-color: var(--button-transparent-bg-color);
    border: 1px solid var(--button-transparent-border-color);
    border-radius: var(--border-radius-small);

    &:hover {
      color: var(--button-transparent-hover-color);
      background-color: var(--button-transparent-hover-bg-color);

      & .btn_arrow {
        color: var(--color-white);
      }
    }

    &.__active {
      color: var(--button-transparent-hover-color);
      background-color: var(--color-base);
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.iconButton {
    padding: 0 rem(15);
    width: rem(46);

    .icon {
      position: relative;
      min-width: rem(20);
      min-height: rem(20);
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }

    &.basket-btn {
      padding: 0 rem(10);
    }

    &.withText {
      @include xs(padding, 0 rem(10));
      @include md(padding, 0 rem(15));
    }

    @include xs-block() {
      padding: 0 rem(10);

      .icon {
        margin-right: 0;
      }

      .text {
        display: none;
        margin-left: 0;
      }
    }

    @include sm-block() {
      .text {
        display: block;
        margin-left: rem(15);
      }
    }

    @include md-block() {
      padding: 0 rem(15);
    }
  }

  &.withText {
    width: auto;
  }

  &.small_padding {
    padding: 0 rem(10);
  }

  &.large_padding {
    padding: 0 rem(20);
  }

  &.big_padding {
    padding: 0 rem(30);
  }

  &.link {
    background-color: transparent;
    color: var(--color-base);
    box-shadow: none;
    border: none;
    font-size: rem(14);
    line-height: rem(14);
    height: rem(14);

    &:hover {
      text-decoration: underline;
    }
  }

  &.small {
    color: var(--button-small-color);
    background-color: var(--color-transparent);
    border: 1px solid var(--button-small-border-color);
    height: rem(39);
    font-size: rem(14);
    line-height: rem(14);

    &:hover {
      color: var(--button-primary-hover-color);
      border-color: var(--button-small-hover-border-color);
    }

    .icon {
      @include xs(display, flex);
      @include md(display, none);

      path {
        stroke: var(--color-black);
      }
    }

    @include xs-block() {
      width: auto;
      border: none;
      font-size: 0;
      padding: 0;
    }

    @include md-block() {
      font-size: rem(14);
      padding: 0 rem(15);
      border: 1px solid var(--button-small-border-color);
    }
  }
}

.btn_close {
  display: block;
  min-width: 12px;
  min-height: 12px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  background: url('../img/svg/close-base.svg') center no-repeat !important;
}

.btn_arrow {
  display: flex;
  min-width: 14px;
  min-height: 8px;
  width: 14px;
  height: 8px;
  cursor: pointer;
  margin-left: rem(10);
  color: var(--color-base);

  transition:
    background .2s ease-in-out,
    border-color .2s ease-in-out,
    color .2s ease-in-out;
}
