.basket_bubble_wrapper {
	position: fixed;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: visibility .3s ease-in-out, opacity .3s ease-in-out;
	cursor: pointer;
	z-index: 9;

	&.__show {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}

	@include xs-block() {
		right: rem(20);
		bottom: rem(20);
	}

	@include md-block() {
		right: rem(40);
		bottom: rem(40);
	}

	.basket_bubble {
		position: relative;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		border: 2px solid var(--color-white);
		color: var(--color-white);
		font-family: "Montserrat-600", sans-serif;
		background-color: var(--color-red);
		border-radius: var(--border-radius-circle);

		@include xs-block() {
			width: rem(34);
			height: rem(46);
			padding: 0 rem(6);
			font-size: rem(8);
			line-height: rem(10);
		}

		@include md-block() {
			width: rem(46);
			height: rem(86);
			padding: 0 rem(20);
			font-size: rem(14);
			line-height: rem(12);

			&:hover {
				filter: drop-shadow(0px 10px 24px rgba(191, 12, 12, 0.2));
			}
		}

		.basket_counter {
			position: absolute;
			right: rem(-6);
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--color-base);
			border-radius: var(--border-radius-circle);
			font-family: "Montserrat-600", sans-serif;
			background-color: var(--color-white);

			@include xs-block() {
				width: rem(8);
				height: rem(20);
				font-size: rem(10);
				line-height: rem(10);
				padding: 0 rem(6);
			}

			@include md-block() {
				width: rem(10);
				height: rem(30);
				font-size: rem(14);
				line-height: rem(14);
				padding: 0 rem(10);
			}
		}
	}
}