.card_wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: var(--border-radius-card);
	color: var(--color-black);
	width: auto;
	background: var(--color-white);
	box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
	transition: transform .3s ease-in-out;

	&:hover {
		@include lg-block() {
			transform: translateY(-20px);
		}
	}

	@include xs-block() {
		padding: rem(30) rem(20);
		height: rem(340);
	}

	@include md-block() {
		padding: rem(44) rem(20);
		height: rem(312);
	}

	@include lg-block() {
		padding: rem(20);
	}

	.card_title {
		text-align: center;
		font: rem(16)/rem(24) "Montserrat", sans-serif;
		flex-basis: 18%;
		flex-shrink: 0;
	}

	.cw_image {
		flex: 1;
		max-width: 100%;
		z-index: 0;
		transition: transform .3s ease-in-out;

		@include xs-block() {
			max-height: rem(180);
		}

		@include md-block() {
			max-height: rem(180);
		}

		@include lg-block() {
			max-height: rem(360);
		}
	}
}