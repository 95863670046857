/**
 Styles for Fancybox
 */

.fancybox-content {
  border-radius: var(--border-radius-popup);
  background: var(--color-white);
  width: rem(420);
  padding: rem(20) !important;

  .title_popup {
    font: rem(32)/rem(38) 'Montserrat-700', sans-serif;
    color: var(--color-black);
    margin-bottom: rem(20);
    padding-right: rem(20);

    @include xs-block() {
      font-size: rem(28);
      line-height: rem(34);
    }

    @include md-block() {
      font-size: rem(32);
      line-height: rem(38);
    }
  }

  .title_subtitle {
    font: rem(14)/rem(24) 'Montserrat', sans-serif;
    color: var(--color-black);
  }

  .btn {
    font: rem(16)/rem(16) "Montserrat-600", sans-serif;

    @include xs-block() {
      height: rem(60);
    }
  }
}

.fancybox-close-small {
  width: rem(14) !important;
  height: rem(14) !important;
  top: rem(18) !important;
  right: rem(18) !important;
  padding: 0 !important;
  background: url('../img/svg/close-base-gray.svg') center no-repeat !important;
  background-size: 100% !important;
  opacity: 1;

  svg {
    display: none !important;
  }
}