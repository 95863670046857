.index_links {
	display: flex;
	flex-direction: column;
	gap: rem(10);
}
/* header style */

html {
	box-sizing: border-box;
}

body {
	box-sizing: border-box;

	&.__hidden {
		position: fixed;
		width: 100%;
		height: 100%;
		overflow: hidden;
		pointer-events: none;

		header,
		.fancybox-content  {
			pointer-events: auto;
		}
	}
}

.header_logo {
	@include xs-block() {
		svg {
			width: rem(130);
			height: rem(30);
		}
	}

	@include md-block() {
		svg {
			width: rem(220);
			height: rem(52);
		}
	}

	@include lg-block() {
		svg {
			width: rem(200);
			height: rem(48);
		}
	}
}

header {
	width: auto;
	z-index: 1;

	@include xs-block() {
		padding-top: rem(30);
		padding-bottom: rem(30);
	}

	@include md-block() {
		padding-top: rem(40);
		padding-bottom: rem(40);
	}

	.header_wrapper {
		display: flex;
		align-items: center;

		@include xs-block() {
			justify-content: center;
		}

		@include lg-block() {
			justify-content: space-between;
		}
	}

	.header_links {
		gap: rem(40);
	}

	@include xs-block() {
		.header_links {
			display: none;
		}

		.create-link {
			display: none;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	@include lg-block() {
		.header_links {
			display: flex;
		}

		.create-link {
			display: block;
		}
	}
}

.cm {
	@include xs-block() {
		padding-top: rem(30);
		padding-bottom: rem(30);
	}

	@include md-block() {
		padding-top: rem(40);
		padding-bottom: rem(40);
	}

	@include lg-block() {
		padding-top: rem(60);
		padding-bottom: rem(60);
	}
}

.page {
	width: 100%;
	z-index: 10;
	transition: all .3s;
	background: var(--color-white);
	box-sizing: border-box;
}

// header_common_wrapper
.header_common_wrapper {
	position: relative;
	display: flex;
	align-items: center;

	@include xs-block() {
		justify-content: center;
		padding-top: rem(30);
		padding-bottom: rem(30);
	}

	@include md-block() {
		padding-top: rem(40);
		padding-bottom: rem(40);
	}

	@include lg-block() {
		justify-content: space-between;
	}

	.h_btn_back {
		position: absolute;
		display: flex;
		left: rem(10);
		width: auto;
		border: none;
		padding: 0;

		.btn_arrow {
			transform: rotate(90deg);
			margin-right: rem(9);
		}

		&:hover {
			background-color: transparent;
		}

		@include md(display, flex);
		@include lg(display, none);

		.btn_text {
			@include xs(display, none);
			@include md(display, block);
			@include lg(display, none);
		}
	}

	.h_close_btn {
		display: none;
		width: rem(16);
		height: rem(16);
		color: var(--color-base);
		cursor: pointer;

		@include lg(display, flex);
	}
}

.form_question_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	&.__hidden {
		display: none;
	}

	@include xs-block() {
		padding-top: rem(10);
		padding-bottom: rem(50);
	}

	@include md-block() {
		padding-top: rem(20);
		padding-bottom: rem(160);
	}

	@include lg-block() {
		max-width: rem(700);
		padding-top: rem(120);
		padding-bottom: rem(140);
	}
}

.last_question_field_form {
	&.__hidden {
		display: none;
	}

	.field_wrapper {
		display: flex;
		flex-direction: column;
		gap: rem(20);
	}

	.text_subtitle {
		font: rem(14)/rem(20) 'Montserrat', sans-serif;
		color: var(--color-black);

		@include xs(margin-bottom, rem(10));
		@include md(margin-bottom, 0);
	}

	.btn {
		font: rem(16)/rem(16) "Montserrat-600", sans-serif;
		min-width: rem(200);

		@include xs(height, rem(60));
		@include md(max-width, fit-content);
	}
}

.q_text_title {
	color: var(--color-black);

	@include xs(font, (rem(22)/rem(30) "Montserrat-700", sans-serif));
	@include md(font, (rem(40)/rem(48) "Montserrat-700", sans-serif));

	@include xs(margin-bottom, rem(20));
	@include md(margin-bottom, rem(30));
}

.questions_wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;

	&.__hidden {
		.q_text_title,
		.qw_actions {
			display: none;
		}
	}

	.qw_proggress {
		display: flex;
		flex-direction: column;
		gap: rem(15);
		font: rem(20)/rem(24) "Montserrat-500", sans-serif;

		@include xs(margin-bottom, rem(40));
		@include md(margin-bottom, rem(60));
		@include lg(margin-bottom, rem(50));
	}

	.qwp_bar_wrapper {
		width: 100%;
		height: rem(6);
		background-color: #e9eef7;
		border-radius: rem(10);

		.qwp_progress_wrapper {
			width: 100%;
			height: rem(6);
		}

		.qwp_progress {
			width: 0;
			height: rem(6);
			background-color: var(--color-base);
			border-radius: rem(10);
			transition: width .3s ease-in-out;
		}
	}

	.qw_actions {
		display: flex;
		gap: rem(20);

		.btn {
			@include xs-block() {
				height: rem(50);
			}

			@include md-block() {
				min-width: inherit;
				width: rem(160);
				height: rem(50);
			}
		}
	}
}

.result_question_wrapper {

}

.result_wrapper_width {
	display: flex;
	justify-content: center;
}

.result_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	&.__questions {
		@include lg-block() {
			max-width: rem(380);
			padding-top: rem(40);
			padding-bottom: rem(80);
		}
	}

	@include xs-block() {
		padding-top: rem(10);
		padding-bottom: rem(50);
	}

	@include md-block() {
		padding-top: rem(40);
		padding-bottom: rem(60);
	}

	@include lg-block() {
		max-width: rem(700);
		padding-top: rem(40);
		padding-bottom: rem(80);
	}

	&.__hidden {
		display: none;
	}

	&.__red {
		.rw_image {
			background-color: #e14142;
		}
	}

	&.__yellow {
		.rw_image {
			background-color: #e8a643;
		}
	}

	&.__green {
		.rw_image {
			background-color: #6ebe45;
		}
	}

	.title_result {
		text-align: center;
		margin-bottom: rem(45);
	}

	.description {
		text-align: center;
		font: rem(16)/rem(24) "Montserrat", sans-serif;
		margin-bottom: rem(30);
	}

	.rw_image {
		position: relative;
		border-radius: var(--border-radius-circle);

		@include xs-block() {
			width: rem(280);
			height: rem(280);
		}

		@include md-block() {
			width: rem(300);
			height: rem(300);
		}

		.image {
			position: absolute;
			left: 0;
			bottom: 0;
		}

		@include xs(margin-bottom, rem(20));
		@include md(margin-bottom, rem(30));
		@include lg(margin-bottom, rem(40));
	}

	.btn {
		font: rem(16)/rem(16) "Montserrat-600", sans-serif;

		@include xs(height, rem(60));
		@include md(min-width, rem(276));
		@include lg(min-width, rem(267));
	}
}

// common wrapper for form page
.form_wrapper_width {
	display: flex;
	justify-content: center;
}

.form_wrapper {
	color: var(--color-black);

	@include xs-block() {
		//padding-top: rem(10);
		padding-bottom: rem(50);
	}

	@include md-block() {
		//padding-top: rem(20);
		padding-bottom: rem(60);
	}

	@include lg-block() {
		max-width: rem(640);
		justify-content: space-between;
		padding-top: rem(20);
		padding-bottom: rem(80);
	}

	.form_content {
		display: flex;
		flex-direction: column;
		gap: rem(20);
	}

	.form_title {
		font: rem(40)/rem(48) 'Montserrat-700', sans-serif;
		margin-bottom: rem(10);

		@include xs-block() {
			font-size: rem(29);
			line-height: rem(38);
			margin-top: rem(10);
		}

		@include md-block() {
			font-size: rem(40);
			line-height: rem(48);
		}
	}

	.form_subtitle {
		font: rem(24)/rem(24) 'Montserrat-700', sans-serif;

		@include xs-block() {
			font-size: rem(20);
			line-height: rem(28);
			margin-top: rem(10);
		}

		@include md-block() {
			font-size: rem(24);
			line-height: rem(24);
		}
		@include lg(margin-top, rem(20));
	}

	.form_text {
		font: rem(14)/rem(20) 'Montserrat', sans-serif;
		margin-bottom: rem(10);
	}

	.btn {
		font: rem(16)/rem(16) "Montserrat-600", sans-serif;
		min-width: rem(200);

		@include xs-block() {
			height: rem(60);
		}

		@include md-block() {
			max-width: fit-content;
			margin-left: auto;
			margin-right: auto;
		}

		@include lg-block() {
			margin-left: inherit;
			margin-right: inherit;
		}
	}

	.select {
		@include xs-block() {
			height: rem(50);
		}

		@include md-block() {
			height: rem(65);
		}
	}

	.select-options {
		height: 162px;
	}
}