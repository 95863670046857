.select-country {

	.select-options {
		min-height: 270px;
	}

	.select-text-help {
		font: rem(12)/rem(14) "Montserrat", sans-serif;
		margin-top: rem(8);

		@include md-block() {
			padding-left: rem(20);
		}

		&.__hidden {
			display: none;
		}
	}

	.selected-country {
		display: flex;
		flex-wrap: wrap;
		gap: rem(10);

		&.__hidden {
			margin-top: 0;
		}

		@include xs(margin-top, rem(10));
		@include md(margin-top, rem(20));

		.bsi_item {
			display: flex;
			align-items: center;
			height: rem(18);
			padding: rem(8) rem(14);
			color: var(--color-white);
			background-color: var(--color-base);
			border-radius: var(--border-radius-big);
			font: rem(14)/rem(14) 'Montserrat-600', sans-serif;

			.bsi_text {
				margin-right: rem(8);
			}

			.item_close_btn {
				cursor: pointer;

				@include xs-block() {
					width: 12px;
					height: 12px;
					svg {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}