/**
 Variables Colors
 */

:root {
  --color-base: #1f4fad;

  --color-white: #fff;
  --color-black: #000;
  --color-transparent: rgba(255, 255, 255, 0);
  --color-blue: #d1eefc;
  --color-dark-blue: #6669a6;
  --color-beige: #efede8;
  --color-brown: #dbd4d1;
  --color-light-black: #29282c;

  --color-cyan: #ccd2dc;
  --color-marine: #272262;

  --color-light-gray: #8494b0;
  --color-extra-light-gray: #eef1f6;
  --color-red: #bf0c0c;

  --color-success: var(--color-black);
  --color-success-hover: rgba(0, 0, 0, .7);
  --color-danger: #f7941d;
  --color-danger-hover: var(--color-transparent);
  --color-primary: #652efe;
  --color-primary-hover: var(--color-white);
  --color-secondary: #d9d9d9;
  --color-secondary-hover: #e0e0e0;
  --color-dark: #878787;
  --color-dark-hover: #6b6b6b;

  // swiper custom vars
  --swiper-theme-color: #2050ae !important;
  --swiper-pagination-bullet-inactive-color: var(--color-transparent);
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 5px;
}
