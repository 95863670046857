.confirm_close_popup {
	@include xs(width, calc(100% - 20px));
	@include md(width, rem(420));

	.content {
		display: flex;
		flex-direction: column;
		gap: rem(20);
		padding-top: rem(20);
	}

	.title_subtitle {
		font: rem(14)/rem(24) 'Montserrat', sans-serif;
		color: var(--color-black);
		margin-bottom: rem(20);
	}

	.bs_actions {
		display: flex;
		gap: rem(19);

		.btn {
			flex: 1;
		}
	}
}
