/**
 * Styles for Input
 */

svg:not(:root) {
  overflow: hidden;
}

.input {
  width: 100%;
  padding: rem(23) rem(15);
  color: var(--color-black);
  font: rem(14)/rem(18) "Montserrat-500", sans-serif;
  vertical-align: bottom;
  border-radius: rem(15);
  border: 1px solid #CDD6E8;
  outline: none;
  background: white;
  box-sizing: border-box;
  transition: all .2s ease-in-out;

  @include xs-block() {
    max-height: rem(50);
  }

  @include md-block() {
    max-height: rem(65);
  }

  &::placeholder {
    color: var(--color-light-gray);
    font-family: "Montserrat-500", sans-serif;
  }

  &:focus {
    border: solid 1px var(--color-dark);
  }

  &:disabled {
    background-color: #eef1f7;
    pointer-events: none;
  }

  &::-ms-clear {
    display: none;
  }

  &.file {
    display: none;
  }

  &.error {
    box-shadow: inset 0 0 0 1px var(--color-red);
  }

  &.dark {
    background: var(--color-extra-light-gray);
    border: none;

    &.not-empty {
      background: var(--color-white);
      box-shadow: inset 0 0 0 1px var(--color-cyan);
    }
  }

  // textarea
  &.textarea {
    @include xs-block() {
      padding: rem(15);
      min-height: rem(110);
      max-height: rem(110);
    }

    @include md-block() {
      min-height: rem(140);
      max-height: rem(140);
      padding: rem(23) rem(20);
    }
  }
}

.input-container {
  display: flex;
  flex-direction: column;

  .input {
    //border: none;
    //background: #eef1f7;
    //padding: rem(20);
    //max-height: rem(54);

    &.file {
      visibility: hidden;
      background: transparent;
      padding: 0;
    }

    &.empty {
      //background: #eef1f7;
    }

    &.not-empty {
      //background: var(--color-white);
      //box-shadow: inset 0 0 0 1px var(--color-cyan);
    }

    &.light {
      background: var(--color-white);
      border: solid 1px var(--color-cyan);

      &.not-empty {
        box-shadow: none;
      }
    }
  }
}

.textarea-container {
  display: flex;
  flex-direction: column;

  .input {
    //line-height: rem(18);

    //@include xs-block() {
    //  padding: rem(15);
    //  height: rem(110);
    //}
    //
    //@include md-block() {
    //  height: rem(94);
    //  padding: rem(23) rem(20);
    //}

    @include xs-block() {
      padding: rem(15);
      min-height: rem(110);
      max-height: rem(110);
    }

    @include md-block() {
      min-height: rem(140);
      max-height: rem(140);
      padding: rem(23) rem(20);
    }

    //&.empty {
    //  background: #eef1f7;
    //}
    //
    //&.not-empty {
    //  background: var(--color-white);
    //  box-shadow: inset 0 0 0 1px var(--color-cyan);
    //}
  }
}

textarea {
  resize: none;
}

.label-input,
.label-textarea {
  font: rem(14)/rem(18) "Montserrat", sans-serif;
  color: var(--color-black);
  margin-bottom: rem(10);

  @include xs-block() {
    display: block;
  }

  @include md-block() {
    display: none;
  }
}

.input__field {
  padding: 20px 0;
  width: 100%;
  background: 0 0;
  font: rem(16)/rem(16) "TTNorms", sans-serif;
  color: var(--color-light-black);
  border: none;
  border-radius: 0;
  -webkit-appearance: none;

  &:focus {
    outline: 0;
  }
}

.input__label {
  position: absolute;
  top: rem(10);
  left: 0;
  display: block;
  width: 100%;
  font: rem(16)/rem(16) "TTNorms", sans-serif;
  color: var(--color-light-black);
  text-align: left;
  padding: 0;
  user-select: none;
  transform-origin: 0 0;
  transition: transform .2s .15s,color 1s;
  transition-timing-function: ease-out;
  pointer-events: none;
}

.input__label-content {
  position: relative;
  display: block;
  padding: rem(20) 0;
  width: 100%;
}

.graphic {
  position: absolute;
  bottom: 0;
  left: 0;
  fill: none;
  max-height: 60px;
}

.graphic--nao {
  pointer-events: none;
  stroke: var(--color-light-black);
  transition: transform .7s,stroke .7s;
  transition-timing-function: cubic-bezier(0,.25,.5,1);
}

.input--filled .input__label,
.input__field:focus+.input__label {
  color: #9b9b9b;
  transform: translate3d(0, -15px,0) scale3d(.75,.75,1);
}

.input--filled .graphico,
.input__field:focus~.graphic {
  stroke: #dddddd;
  transform: translate3d(-66.6%,0,0);
}

textarea {
  resize: none;
}

.label_input,
.label_textarea {
  font: rem(14)/rem(14) "Fregat-Bold", sans-serif;
  color: var(--color-marine);
  margin-bottom: rem(5);
}

// tooltip
[data-tippy-root] {
  font-family: Fregat;
}

input {
  -webkit-appearance: none;
  background: red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


// file wrapper
.file-container {
  display: flex;
  flex-direction: column;

  .label {
    font: rem(14)/rem(14) "Fregat-Bold", sans-serif;
    color: var(--color-marine);
    margin-bottom: rem(5);
  }
}

.file-container-input {
  width: 100%;
  display: flex;
  align-items: center;
  height: rem(46);

  @include xs-block() {
    flex-wrap: wrap;
    height: auto;
    margin-bottom: rem(20);
  }

  @include md-block() {
    height: rem(34);
    margin-bottom: 0;
    flex-wrap: nowrap;
  }

  .file-input {
    margin-right: rem(20);
    flex-shrink: 0;
    width: rem(112);
    font-size: rem(14);
    height: rem(34);
    padding: rem(10);
  }

  .file-name {
    display: block;
    text-overflow: ellipsis;
    font-family: Fregat, sans-serif;
    font-size: rem(14);
    line-height: rem(16);
    overflow: hidden;
    white-space: nowrap;
    color: var(--color-marine);

    @include xs(max-width, rem(100));
    @include md(max-width, rem(130));
  }

  .file-message {
    font-family: Fregat, sans-serif;
    font-size: rem(14);
    line-height: rem(16);
    color: var(--color-marine);
    white-space: nowrap;
    opacity: .6;

    @include xs-block() {
      margin-top: rem(15);
      margin-left: 0;
      font-size: rem(12);
    }

    @include md-block() {
      margin-top: 0;
      margin-left: auto;
      font-size: rem(14);
    }
  }
}
