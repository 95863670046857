/*
 * footer style
*/

footer {
  position: relative;
  box-sizing: border-box;
  color: var(--color-black);

  .footer_logo {
    @include xs-block() {
      svg {
        width: rem(130);
      }
      margin-bottom: rem(30);
    }

    @include md-block() {
      svg {
        width: rem(200);
      }
      margin-bottom: rem(40);
    }
  }

  .footer_border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(6);
    background-color: var(--color-red);
    background-image: url("../img/svg/footer_border.svg");
    background-size: contain;
  }

  .footer_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @include xs-block() {
      padding-top: rem(36);
      padding-bottom: rem(30);
    }

    @include md-block() {
      padding-top: rem(46);
      padding-bottom: rem(40);
    }

    @include lg-block() {
      padding-top: rem(60);
      padding-bottom: rem(40);
    }

    .fc_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--color-base);
    }
  }

  .footer_bottom__wrapper {
    width: 100%;
    padding: rem(15) 0;
    border-top: 1px solid #eee;

    @include xs(padding, rem(20) 0);
    @include md(padding, rem(20) 0);
  }

  .fb_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include xs-block() {
      flex-direction: column;
      gap: rem(10);
    }

    @include md-block() {
      flex-direction: row;
    }

    .fb_text {
      font: rem(12)/rem(24) "Montserrat", sans-serif;

      @include xs-block() {
        font-size: rem(11);
        line-height: rem(11);
      }

      @include md-block() {
        font-size: rem(12);
        line-height: rem(24);
      }
    }

    .fb_links {
      display: flex;
      gap: rem(20);
    }
  }

  .f_text {
    font: rem(14)/rem(24) "Montserrat-500", sans-serif;
    color: var(--color-black);
    margin-bottom: rem(10);
  }

  .f_link {
    font: rem(12)/rem(24) "Montserrat-500", sans-serif;
    color: var(--color-base);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &.__tel {
      font: rem(24)/rem(24) "Montserrat-700", sans-serif;
      color: var(--color-black);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      @include xs-block() {
        font-size: rem(18);
        margin-bottom: rem(20);
      }

      @include md-block() {
        margin-bottom: rem(40);
        font-size: rem(24);
      }
    }
  }

  .fl_link {
    font: rem(64)/rem(64) "TTNorms", sans-serif;

    @include xs-block() {
      font-size: rem(42);
      line-height: rem(42);
    }

    @include md-block() {
      font-size: rem(64);
      line-height: rem(64);
    }

    &:hover {
      text-decoration: underline;
    }

    &:not(:last-child) {
      @include xs(margin-bottom, rem(40));
      @include md(margin-bottom, rem(60));
    }
  }

  .fb_item {
    display: flex;
    gap: rem(20);
  }

  .soc_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(40);
    height: rem(40);
    background-color: var(--color-white);
    border-radius: var(--border-radius-circle);
    color: var(--color-base);
    box-shadow: 0 0 0 2px var(--color-base);
    transition: color .2s ease-in-out, background-color .3s ease-in-out;

    .icon {
      display: flex;
      flex-shrink: 0;
      width: rem(20);
      min-width: rem(20);
    }

    @include md-block() {
      &:hover {
        background-color: var(--color-base);
        color: var(--color-white);
      }
    }
  }
}