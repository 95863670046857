/* text
 * _m        - main
 * _bc       - bread crumbs
 * _img_d    - image description
 * _f        - footer
 * _fb       - footer bottom
*/

.text_description {
  color: var(--color-black);
  opacity: .8;

  @include xs-block() {
    font: rem(16)/rem(24) "TTNorms", sans-serif;
  }

  @include md-block() {
    font: rem(18)/rem(28) "TTNorms", sans-serif;
  }
}

.text_description_extra {
  color: var(--color-black);
  opacity: .5;

  @include xs-block() {
    font: rem(16)/rem(24) "TTNorms", sans-serif;
  }

  @include md-block() {
    font: rem(14)/rem(20) "TTNorms", sans-serif;
  }
}

.text_m {
  font: rem(16)/rem(28) "Open Sans", sans-serif;
  margin-bottom: rem(20);

  @include xs(font, (rem(15)/rem(24) "Open Sans", sans-serif));
  @include md(font, (rem(16)/rem(28) "Open Sans", sans-serif));
}

.text_bc {
  color: var(--color-light-gray);

  @include xs(font, (rem(12)/rem(14) "Open Sans", sans-serif));
  @include md(font, (rem(14)/rem(14) "Open Sans", sans-serif));
}

.text_img_d {
  font: rem(12)/rem(12) "Open Sans", sans-serif;
  color: #303b44;
  margin-top: rem(10);
}

.textCursive {
  font-style: italic;
}

.textBold {
  font-weight: bold;
}

.textThrough {
  text-decoration: line-through;
}

.textUnderline {
  text-decoration: underline;
}

.textLink {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

/* link */
a {
  text-decoration: inherit;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
}

a[href^=tel] {
  color: inherit;
}

.link {
  font: rem(14)/rem(28) "Open Sans", sans-serif;
}

.dark_link {
  font: rem(14)/rem(16) "Montserrat-600", sans-serif;
  color: var(--color-black);
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: var(--color-base);
  }
}

.text_normal {
  font: rem(14)/rem(14) "Fregat", sans-serif;
}

/** Transformation */
.textLowercase {
  text-transform: lowercase !important;
}

.textUppercase {
  text-transform: uppercase !important;
}

.textCapitalize {
  text-transform: capitalize !important;
}

.textNowrap {
  white-space: nowrap !important;
}

.textCenter {
  text-align: center !important;
}

.textUnderline {
  text-decoration: underline !important;
}

.textTruncate {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.fontWeightLight {
  font-weight: 300 !important;
}

.fontWeightNormal {
  font-weight: 400 !important;
}

.fontWeightSemibold {
  font-weight: 600 !important;
}

.fontWeightBold {
  font-weight: 700 !important;
}
