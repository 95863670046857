@import "import/normalize";
@import "import/smart-grid";

@import 'scroll/scroll';
@import "fancybox/jquery_fancybox";

//@import 'animate.css/animate.min';
@import 'tippy/typpy';
@import 'swiper/swiper';
@import 'swiper/modules/pagination/pagination';
