$select-color: var(--color-black);
$hover-background: var(--color-base);
$select-background: #f4f7fc;
$select-border-color: #ccd6e9;
$select-width: 100%;
$select-height: rem(65);

.select-container {
  position: relative;
  width: 100%;

  &.sort {
    display: flex;
    align-items: center;
    width: max-content;

    .select-label {
      margin-bottom: 0;
      margin-right: rem(10);
    }

    .select {
      height: rem(14);
      font-size: rem(12);
    }

    .select-styled {
      position: relative;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
      overflow: inherit;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: rem(1);
        width: 100%;
        height: 1px;
        background: var(--color-black);
      }

      &:after {
        top: rem(3);
        right: rem(-25);
        background: url('../img/svg/arrow-black.svg') no-repeat center;
      }
    }

    .select-options {
      top: calc(100% + 15px);
      left: rem(-10);
      width: rem(161);

      li {
        padding: rem(10) rem(15);
      }
    }
  }

  &.small {
    .select-styled {
      &:after {
        width: rem(12);
      }
    }
  }

  &.error {
    .select-label {
      color: #ef4136;
    }

    .select-styled {
      box-shadow: inset 0 0 0 1px #ef4136;

      &:after {
        background: url('../img/svg/arrow-error.svg') no-repeat center;
        background-size: 100%;
      }
    }
  }
}

.select-label {
  cursor: pointer;
  pointer-events: none;
  z-index: 1;

  @include xs-block() {
    font: rem(14)/rem(18) 'Montserrat', sans-serif;
    color: var(--color-black);
    margin-bottom: rem(10);
  }

  @include md-block() {
    position: absolute;
    top: rem(14);
    left: rem(20);
    font: rem(12)/rem(14) 'Montserrat', sans-serif;
    color: rgba(0, 0, 0, .5);
    margin-bottom: 0;
  }
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font: rem(14)/rem(14) 'Montserrat-500', sans-serif;
  color: $select-color;
  width: $select-width;
  height: $select-height;
}

.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: var(--color-white);
  white-space: nowrap;
  border-radius: rem(15);
  box-shadow: inset 0 0 0 1px #ccd6e9;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: all .2s ease-in;

  @include xs-block() {
    padding: rem(18) rem(40) rem(15) rem(15);

    &:after {
      top: rem(20);
      right: rem(15);
    }
  }

  @include md-block() {
    padding: rem(35) rem(45) rem(14) rem(20);

    &:after {
      top: rem(28);
      right: rem(20);
    }
  }

  &:after {
    content:"";
    position: absolute;
    width: rem(17);
    height: rem(9);
    top: rem(28);
    right: rem(20);

    background: url('../img/svg/arrow/arrow-base.svg') no-repeat center;
    background-size: contain;
    transition: all .2s ease-in;
  }

  &:active, &.active {
    &:after {
      transform: rotate(180deg);
    }
  }
}

.select-options {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-darker);
  border-radius: var(--border-radius);
  max-height: rem(270);
  z-index: 3;

  @include xs-block() {
    overflow-y: scroll;
  }

  @include lg-block() {
    overflow-y: inherit;
  }

  li {
    margin: 0;
    padding: rem(20);
    transition: all .15s ease-in;

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }

    &:last-child {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }

    &:hover {
      color: var(--color-white);
      background: $hover-background;
    }

    &.active {
      cursor: default;
      color: var(--color-base);

      &:hover {
        background-color: transparent;
      }
    }

    &[rel="hide"] {
      display: none;
    }
  }

  .mCSB_container {
    margin-right: 0 !important;
  }

  .mCSB_dragger_bar {
    background-color: rgba(0, 0, 0, 0.4) !important;
  }

  .mCSB_draggerRail {
    background: transparent !important;
  }
}