/**
 * Styles for Button with Icon
 */

.icon_button {
  position: relative;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 36px;
  overflow: visible;
  color: var(--color-white);
  vertical-align: middle;
  background: var(--color-base);
  box-shadow: inset 0 0 0 2px var(--color-white);
  border-radius: var(--border-radius-large);
  cursor: pointer;
  border: none;
  transition: background-color .2s ease-in-out;

  &:hover {
    color: var(--color-base);
    background: var(--color-white);
  }

  .icon {
    position: relative;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


