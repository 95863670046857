@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat-500';
  src: url('../fonts/Montserrat-500.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat-600';
  src: url('../fonts/Montserrat-600.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat-700';
  src: url('../fonts/Montserrat-700.woff') format('woff');
}
