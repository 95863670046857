.basket_popup {
	@include xs(width, calc(100% - 20px));
	@include md(width, rem(420));

	.content {
		display: flex;
		flex-direction: column;
		gap: rem(20);
		padding-top: rem(20);
	}

	.items_scroll {
		max-height: rem(130);
		overflow: hidden;

		.mCSB_container {
			margin-right: 0 !important;
			display: flex;
			flex-direction: column;
		}

		.mCSB_dragger_bar {
			background-color: var(--color-base) !important;
		}

		.mCSB_draggerRail {
			background: transparent !important;
		}
	}

	.bs_items {
		display: flex;
		flex-wrap: wrap;
		gap: rem(10);

		.bsi_item {
			display: none;
			align-items: center;
			height: rem(34);
			padding: rem(8) rem(14);
			color: var(--color-white);
			background-color: var(--color-base);
			border-radius: var(--border-radius-big);
			font: rem(14)/rem(14) 'Montserrat-600', sans-serif;

			@include xs-block() {
				font-size: rem(12);
				line-height: rem(12);
			}

			@include md-block() {
				font-size: rem(14);
				line-height: rem(14);
			}

			&.__accept {
				display: flex;
			}

			.bsi_text {
				margin-right: rem(8);
			}

			&.__all {
				display: flex;
				background-color: transparent;
				color: var(--color-red);
				box-shadow: inset 0 0 0 2px var(--color-red);
				cursor: pointer;

				&.__hidden {
					display: none;
				}

				&:hover {
					color: var(--color-white);
					background-color: var(--color-red);
				}
			}

			.item_close_btn {
				cursor: pointer;

				@include xs-block() {
					width: 10px;
					height: 10px;
					svg {
						width: 100%;
						height: auto;
					}
				}

				@include md-block() {
					width: 14px;
					height: 14px;
					svg {
						width: initial;
						height: initial;
					}
				}
			}
		}
	}

	.text_subtitle {
		font: rem(14)/rem(24) 'Montserrat', sans-serif;
		color: var(--color-black);
	}

	.bs_actions {
		display: flex;
		gap: rem(19);

		.btn {
			flex: 1;
		}
	}
}
