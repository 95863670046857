/* title
 * _h1    - h1        - 32px/38px
 * _h2    - h2        - 24px/34px
 * _h3    - h3        - 18px/24px
 * _h4    - h4        - 16px/24px
 * _p     - page      - 24px/24px
*/

.title_h1 {
  color: var(--color-black);
  margin-bottom: rem(10);

  @include xs(font, (rem(20)/rem(32) "Montserrat-700", sans-serif));
  @include md(font, (rem(32)/rem(44) "Montserrat-700", sans-serif));
  @include lg(font, (rem(34)/rem(44) "Montserrat-700", sans-serif));
}

.title_h2 {
  font: rem(18)/rem(28) "Montserrat", sans-serif;
  color: var(--color-black);
  opacity: .8;

  @include xs-block() {
    font-size: rem(16);
  }

  @include md-block() {
    font-size: rem(18);
  }
}

.title_h3 {
  @include xs(font, (rem(16)/rem(24) Fregat-Bold, sans-serif));
  @include md(font, (rem(18)/rem(24) Fregat-Bold, sans-serif));

  margin-bottom: rem(20);
}

.title_h4 {
  @include xs(font, (rem(14)/rem(20) Fregat-Bold, sans-serif));
  @include md(font, (rem(16)/rem(24) Fregat-Bold, sans-serif));

  margin-bottom: rem(20);
}

.title_p {
  @include xs(font, (rem(20)/rem(20) Fregat-Bold, sans-serif));
  @include md(font, (rem(24)/rem(24) Fregat-Bold, sans-serif));
}

.title_page {
  font: rem(20)/rem(24) Fregat-Bold, sans-serif;
}

.title_result {
  color: var(--color-black);

  @include xs(font, (rem(25)/rem(34) "Montserrat-700", sans-serif));
  @include md(font, (rem(40)/rem(48) "Montserrat-700", sans-serif));
}